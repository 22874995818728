import {
  MediaControlBar,
  MediaController,
  MediaFullscreenButton,
  MediaLoadingIndicator,
  MediaMuteButton,
  MediaPlaybackRateButton,
  MediaPlayButton,
  MediaPosterImage,
  MediaTimeRange,
} from 'media-chrome/dist/react';
import { FC, useEffect, useState } from 'react';
import download from '../../assets/images/download.svg';
import hyperlink from '../../assets/images/Hyperlink.svg';
import questionMark from '../../assets/images/question-mark-circle.svg';
import share from '../../assets/images/share.png';
import { CopyLink } from '../../components/CopyLink';
import { VideoModal } from '../../components/VideoModal';
import APIService from '../../network/APIService';
import { useNavigate } from 'react-router-dom';

const VideoLibrary: FC = () => {
  const [isModelOpen, setIsModelOpen] = useState({
    isCopyLink: false,
    isVideo: false,
  });
  const [videoData, setVideoData] = useState({
    fullPath: '',
    thumbnailPath: '',
    format: '',
  });
  const [videoLink, setVideoLink] = useState('');
  const [cutData, setCutData] = useState<any[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [atLeastOneChecked, setAtLeastOneChecked] = useState(false);
  const [user, setUser] = useState<any>({});
  const [isPaid, setIsPaid] = useState<Boolean>(false);
  const [downloading, setDownloading] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleProceedToPayment = async () => {
    const scheduled = cutData
      .filter((d: any) => d.selected)
      .map((d: any) => d.id);
    if (scheduled.length === 0) return;

    const response = await APIService.payment(scheduled).catch(console.error);
    const url = response.data.checkout_url;

    window.location.href = url;
  };

  const handleCheckboxChange = (index: any) => {
    const updatedData = [...cutData];
    updatedData[index].selected = !updatedData[index].selected;
    setCutData(updatedData);

    const allSelected = updatedData.every((item) => item.selected);
    console.log(allSelected);
    setAllChecked(allSelected);

    const atLeastOneSelected = updatedData.some((item) => item.selected);
    setAtLeastOneChecked(atLeastOneSelected);
  };

  useEffect(() => {
    fetchCuts();
  }, []);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent =
        typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(userAgent) ? true : false);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await APIService.getLoggedInUser();
      return user.data
    };
    fetchUser().then(user => {
      setUser(user);
    }).catch(console.error);
  }, []);

  const handleOpenModal = (object: any) => {
    if (!isMobile) {
      setIsModelOpen((prev) => ({
        ...prev,
        isVideo: true,
      }));
      setVideoData({
        fullPath: object.fullPath,
        thumbnailPath: object.thumbnailPath,
        format: object.format,
      });
    }
  };

  const fetchCuts = async () => {
    try {
      const response = await APIService.getPreviews();
      const canProceed =
        response &&
        response.status === 200 &&
        response.data &&
        response.data.length >= 3;
      if (canProceed) {
        setCutData(response.data);
      } else {
        navigate('/inprogress');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const videoReady = async (video: any) => {
    video.video_ready = true;
    setCutData([...cutData]);
  };
  const downloadVideo = async (path: string, name: string) => {
    try {
      const parsePath = new URL(path)
      let pathName: any = parsePath.pathname.split('/')
      const [_, __,...rest] = pathName
      pathName = [...rest].join('/')
      const downloadUrl = await APIService.downloadFile(pathName)
      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl.data;
      downloadLink.download = name;
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the DOM
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Error downloading video:', error);
    } finally {
      setDownloading(null)
    }
  };

  const reorderedCutData =
    cutData &&
    cutData.length > 0 &&
    cutData.sort((a, b) => {
      if (a.format === '16-9' && b.format !== '16-9') return -1;
      if (a.format !== '16-9' && b.format === '16-9') return 1;
      if (a.format === '9-16' && b.format !== '9-16') return 1;
      if (a.format !== '9-16' && b.format === '9-16') return -1;
      return 0;
    });

  const handleSetIsModelClose = () => {
    setIsModelOpen((prevState: any) => {
      const newState: any = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = false;
      });

      return newState;
    });
  };

  useEffect(() => {
    for (const cutVideo of cutData) {
      if (cutVideo.preview) {
        setIsPaid(true)
      }
    }
  }, [cutData]);

  return (
    <div>
      {isModelOpen.isCopyLink && (
        <div className='fixed inset-0 bg-black opacity-50 z-50'></div>
      )}

      {isModelOpen.isVideo && (
        <div className='fixed inset-0 bg-borderColor opacity-1 z-50'></div>
      )}

      <div className='flex flex-col md:flex-row justify-between px-4 md:px-16 space-y-4 md:space-y-0'>
        <div>
          <div className='font-semibold text-2xl text-gray-900 flex items-center'>
            Preview Your Graduation Videos
          </div>
          {allChecked ? (
            <div
              className='bg-lightPink p-4 rounded-lg mt-2'
              style={{ width: 'fit-content' }}
            >
              <span className='font-normal text-dimGrayText text-lg'>Get</span>{' '}
              <span className='bg-primary text-white p-2 rounded-full mx-2'>
                33% off
              </span>
              <span className='font-normal text-dimGrayText text-lg'>
                For 3 Clip Bundle Purchase
              </span>{' '}
            </div>
          ) : (
            <div
              className='bg-lightPink p-4 rounded-lg mt-2'
              style={{ width: 'fit-content' }}
            >
              <span className='font-normal text-dimGrayText text-lg'>
                3 - Clips Bundle -
              </span>{' '}
              <span className='text-primary font-medium text-lg'>$49.95</span>
              <span className='bg-primary text-white p-2 rounded-full ml-2'>
                33% off
              </span>
            </div>
          )}
        </div>
        <div className='flex justify-center md:justify-end'>
          <button
            className={`bg-primary w-full md:w-auto h-10 text-white font-medium py-2 px-16 rounded focus:outline-none focus:shadow-outline ${!atLeastOneChecked
              ? 'opacity-50 cursor-not-allowed'
              : 'opacity-100 cursor-pointer'
              }`}
            type='button'
            onClick={() => handleProceedToPayment()}
            disabled={!atLeastOneChecked}
          >
            Proceed to payment
          </button>
        </div>
      </div>

      <div className='p-2'>
        <div className='flex justify-center flex-wrap'>
          {reorderedCutData &&
            reorderedCutData.length > 0 &&
            reorderedCutData
              .map((object, index) => (
                <div key={index} className='w-full md:w-1/2 lg:w-1/3 p-4'>
                  <div className='bg-white rounded-lg  grid grid-cols-1 gap-0  px-4'>
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <div>
                        {object.format === '9-16' && (
                          <>
                            <span className='font-semibold text-2xl text-black'>
                              Portrait
                            </span>{' '}
                            <span className='font-normal text-lg text-dimGrayText'>
                              {object.format.replace('-', ':')}
                            </span>
                          </>
                        )}
                        {object.format === '1-1' && (
                          <>
                            <span className='font-semibold text-2xl text-black'>
                              Square
                            </span>{' '}
                            <span className='font-normal text-lg text-dimGrayText'>
                              {object.format.replace('-', ':')}
                            </span>
                          </>
                        )}
                        {object.format === '16-9' && (
                          <>
                            <span className='font-semibold text-2xl text-black'>
                              Landscape
                            </span>{' '}
                            <span className='font-normal text-lg text-dimGrayText'>
                              {object.format.replace('-', ':')}
                            </span>
                          </>
                        )}
                      </div>
                      <div className='text-primary font-medium text-lg'>
                        $24.95
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <div className='flex justify-between pt-2'>
                        <button
                          type='button'
                          className='disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center py-2 xl:px-6 lg:px-2 md:px-4 px-2 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg'
                          onClick={() => {
                            setDownloading(index)
                            downloadVideo(object.fullPath, object.fileName);
                            // handleDownload(object.fullPath, object.fileName)
                          }}
                          disabled={object.preview || downloading === index}
                        >
                          <img src={download} alt='Download' className='mr-1' />
                          {downloading === index ? 'Downloading...' : 'Download'}
                        </button>
                        <button
                          type='button'
                          className='disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center py-2 xl:px-6 lg:px-2 md:px-4 px-2 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg'
                          onClick={() => {
                            window.open(`mailto:${user?.email}?subject=Video Link: ${object?.fullPath}!`)
                            // window.open('mailto:someone@example.com?subject=Your%20Subject&body=Your%20message%20goes%20here')
                          }
                          }
                          disabled={object.preview}
                        >
                          <img src={share} alt='Share Link' className=' mr-1' />
                          Share Link
                        </button>
                        <button
                          type='button'
                          className='disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center py-2 xl:px-6 lg:px-2 md:px-4  px-2 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg '
                          onClick={() => {
                            setIsModelOpen((prev) => ({
                              ...prev,
                              isCopyLink: true,
                            }));
                            setVideoLink(object.fullPath);
                          }}
                          disabled={object.preview}
                        >
                          <img src={hyperlink} alt='Copy Link' className='mr-1' />
                          Copy Link
                        </button>
                      </div>

                      <div className='flex items-center my-4'>
                        <input
                          id={`checkbox-${index}`}
                          type='checkbox'
                          value=''
                          className='w-4 h-4 bg-gray-100 border-ash rounded focus:ring-2 accent-primary'
                          checked={object.selected}
                          onChange={() => handleCheckboxChange(index)}
                          disabled={!object.preview}
                        />
                        <label
                          htmlFor={`checkbox-${index}`}
                          className='ml-2 text-sm font-medium text-black'
                        >
                          Purchase this video without watermark
                        </label>
                      </div>
                    </div>
                    <div
                      className='flex items-center justify-center'
                      style={{ display: object.video_ready ? 'none' : 'none' }}
                    >
                      <p className='text-center text-lg font-bold'>Loading ...</p>
                    </div>
                    <div className='flex items-center justify-center'>
                      <div
                        style={
                          object.format === '1-1'
                            ? { height: '350px', width: '350px' }
                            : object.format === '9-16'
                              ? { width: '240px' }
                              : undefined
                        }
                      >
                        <MediaController
                          ref={(node: HTMLElement) => console.log('ref')}
                          defaultSubtitles
                        >
                          <video
                            slot='media'
                            src={object.fullPath}
                            preload='auto'
                            muted
                            crossOrigin=''
                            onCanPlay={() => videoReady(object)}
                            onClick={() => handleOpenModal(object)}
                          >
                            <track
                              label='thumbnails'
                              default
                              kind='metadata'
                              src={object.fullPath}
                            />
                            <track
                              label='English'
                              kind='captions'
                              srcLang='en'
                              src='./vtt/en-cc.vtt'
                            />
                          </video>
                          <MediaPosterImage
                            slot='poster'
                            src={object.thumbnailPath}
                          ></MediaPosterImage>
                          <MediaLoadingIndicator
                            noautohide
                            slot='centered-chrome'
                            style={{
                              '--media-loading-indicator-icon-height': '350px',
                            }}
                          ></MediaLoadingIndicator>
                          {isMobile && (
                            <MediaControlBar>
                              <MediaPlayButton></MediaPlayButton>
                              <MediaTimeRange></MediaTimeRange>
                              <MediaMuteButton></MediaMuteButton>
                              <MediaPlaybackRateButton></MediaPlaybackRateButton>
                              <MediaFullscreenButton></MediaFullscreenButton>
                            </MediaControlBar>
                          )}
                        </MediaController>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
      {isModelOpen.isCopyLink && (
        <CopyLink
          handleSetIsModelClose={handleSetIsModelClose}
          link={videoLink}
        />
      )}
      {isModelOpen.isVideo && (
        <VideoModal
          handleSetIsModelClose={handleSetIsModelClose}
          videoData={videoData}
        />
      )}
    </div>
  );
};

export default VideoLibrary;
