import React, { useState } from 'react';
import downIcon from '../../assets/images/down.svg';

const faqs = [
  {
    question: 'What is the format of the videos?',
    answer:
      'The video format is based on the video provided to Gradcut by the University live-streaming supplier. It is normally in full HD in various aspect ratios 16:9, 9:16, 1:1. The videos are delivered as .mp4 - H264 codec for best compatibility.',
  },
  {
    question: 'Why can’t I watch the videos?',
    answer:
      'There could be several reasons. Please ensure you have a stable internet connection and that your device supports the video format.',
  },
  {
    question: 'How to download the videos?',
    answer:
      'You can download the videos by clicking the download button next to the video on your dashboard.',
  },
  {
    question: 'How long are the videos available for?',
    answer: 'The videos are available for one year from the date of the event.',
  },
  {
    question: 'What to do if it’s not me on the videos?',
    answer:
      'If you see someone else in your video, please contact our support team for assistance.',
  },
  {
    question: 'Where can I find my photos?',
    answer: 'Your photos can be found in the photos section of your dashboard.',
  },
  {
    question: 'Can I purchase the full video of the ceremony?',
    answer:
      'Yes, the full video of the ceremony is available for purchase. Please check your dashboard for more details.',
  },
  {
    question: 'The page says my video is still processing',
    answer:
      'Video processing can take some time. Please check back later or contact support if the issue persists.',
  },
  {
    question: 'I can’t download my videos',
    answer:
      'If you are experiencing issues downloading your videos, please try a different browser or contact our support team.',
  },
  {
    question: 'How to download your graduation videos on an iPhone?',
    answer:
      'To download videos on an iPhone, use the Safari browser and follow the download prompts.',
  },
];
type FAQ = {
  question: string;
  answer: string;
};

type AccordionItemProps = {
  faq: FAQ;
  isOpen: boolean;
  onToggle: () => void;
};

const AccordionItem: React.FC<AccordionItemProps> = ({
  faq,
  isOpen,
  onToggle,
}) => {
  return (
    <div className='border-b border-borderColor'>
      <button
        onClick={onToggle}
        className='w-full text-left py-4 px-4 focus:outline-none flex justify-between items-center'
      >
        <span className='text-[22px] font-[500] text-[#050505]'>
          {faq.question}
        </span>
        <span>
          <img
            width={'15px'}
            src={downIcon}
            alt='downIcon'
            className={isOpen ? 'transform rotate-180' : ''}
          />
        </span>
      </button>
      {isOpen && (
        <div className='px-4 pb-4 text-[16px] font-[400] text-[#707070]'>
          {faq.answer}
        </div>
      )}
    </div>
  );
};

const Accordion: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const [parent, setParent] = useState(false);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const onParentToggle = () => {
    setParent(!parent);
  };

  return (
    <>
      <div className='bg-grayLight'>
        <button
          onClick={onParentToggle}
          className='w-full text-left py-4 px-4 md:px-16 focus:outline-none flex justify-start items-center'
        >
          <span className='h-11 w-11 border border-solid border-borderColor rounded-full p-2.5 mr-3.5'>
            <img
              src={downIcon}
              alt='downIcon'
              className={parent ? 'transform rotate-180' : ''}
            />
          </span>

          <span className='text-[22px] font-semibold text-[#050505]'>
            Frequently Asked Questions
          </span>
        </button>
        {parent && (
          <div className='py-4 px-4 md:px-16 grid grid-cols-1 md:grid-cols-2 gap-4'>
            {faqs.map((faq, index) => (
              <AccordionItem
                key={index}
                faq={faq}
                isOpen={openIndex === index}
                onToggle={() => handleToggle(index)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-between h-auto md:h-[72px] items-center bg-graySightlyDark px-4 md:px-16 font-sans font-[400] text-[#292929] text-sm space-y-2 md:space-y-0">
        <div>© 2024 Edit on the Spot Pty Ltd trading as Gradcut</div>
        <div>
          <a href="https://gradcut.com/privacy-policy/">Privacy Statement</a>{" "}
          &nbsp;|&nbsp;
          <a
            href="https://www.gradcut.com/cookie-statement/"
          >
            Cookie Statement
          </a>
        </div>
        <div>Support Email: support@gradcut.com</div>
      </div>
    </>
  );
};

export default Accordion;
