import {
  MediaControlBar,
  MediaController,
  MediaFullscreenButton,
  MediaLoadingIndicator,
  MediaMuteButton,
  MediaPlaybackRateButton,
  MediaPlayButton,
  MediaPosterImage,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaTimeRange,
} from 'media-chrome/dist/react';
import closeIcon from '../../assets/images/clear.svg';

interface CopyProps {
  handleSetIsModelClose: () => void;
  videoData: { fullPath: string; thumbnailPath: string; format: string };
}

const VideoModal: React.FC<CopyProps> = ({
  handleSetIsModelClose,
  videoData,
}) => {
  const onClose = async () => {
    handleSetIsModelClose();
  };

  return (
    <div className='flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50'>
      <div className='m-2'>
        <div
          className='border-0  relative flex flex-col w-full p-3 outline-none focus:outline-none'
          style={
            videoData.format === '1-1'
              ? { height: '450px', width: '450px' }
              : videoData.format === '9-16'
              ? { width: '400px' }
              : { height: '800px', width: '800px' }
          }
        >
          <div className='flex justify-end'>
            <button onClick={onClose} className='bg-white'>
              <img src={closeIcon} alt='close icon' />
            </button>
          </div>

          <div className='flex justify-between items-center my-2'>
            <MediaController
              ref={(node: HTMLElement) => console.log('ref', node)}
              defaultSubtitles
            >
              <video
                slot='media'
                src={videoData.fullPath}
                preload='auto'
                muted
                crossOrigin=''
              >
                <track
                  label='thumbnails'
                  default
                  kind='metadata'
                  src={videoData.fullPath}
                />
                <track
                  label='English'
                  kind='captions'
                  srcLang='en'
                  src='./vtt/en-cc.vtt'
                />
              </video>
              <MediaPosterImage
                slot='poster'
                src={videoData.thumbnailPath}
              ></MediaPosterImage>
              <MediaLoadingIndicator
                noautohide
                slot='centered-chrome'
                style={{
                  '--media-loading-indicator-icon-height': '350px',
                }}
              ></MediaLoadingIndicator>
              <MediaControlBar>
                <MediaPlayButton></MediaPlayButton>
                <MediaTimeRange></MediaTimeRange>
                <MediaSeekBackwardButton
                  seekOffset={10}
                ></MediaSeekBackwardButton>
                <MediaSeekForwardButton
                  seekOffset={10}
                ></MediaSeekForwardButton>
                <MediaMuteButton></MediaMuteButton>
                <MediaPlaybackRateButton></MediaPlaybackRateButton>
                <MediaFullscreenButton></MediaFullscreenButton>
              </MediaControlBar>
            </MediaController>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
