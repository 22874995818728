import { FC } from 'react';
import giftIcon from '../../assets/images/gift.svg';

const Inprogress: FC = () => {
  return (
    <div
      className=' p-4'
      style={{ display: 'flex', justifyContent: 'center', height: '74%' }}
    >
      <div className='text-center bg-white p-6 '>
        <div style={{ fontWeight: '600', fontSize: '22px', color: '#050505' }}>
          We are processing your videos
        </div>
        <p>&nbsp;</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={giftIcon} alt='giftIcon' />
        </div>

        <div style={{ color: '#707070', fontSize: '16px', maxWidth: '600px' }}>
          <p>&nbsp;</p>

          <div>
            Thanks for checking in and congrats on your graduation! 🎓✨
          </div>
          <p>&nbsp;</p>
          <div>
            We are working hard to deliver your videos within 24 hours. We will
            send an email to your University address when it’s ready.
          </div>
          <div>If you do not hear from us within 24 hours, please email</div>
          <a href='mailto:support@gradcut.com'>support@gradcut.com</a>
          <p>&nbsp;</p>
          <div>
            P.S. Gradcut is a young startup, this is the first time we are
            offering this service and improving it every day. Thank you for your
            patience. 😊
          </div>
          <p>&nbsp;</p>
          <p>The Gradcut Team</p>
        </div>
      </div>
    </div>
  );
};

export default Inprogress;
