import axios from 'axios';
import { env } from "../env";

const API_BASE_URL = env.API_BASE_URL;

class APIService {
  api: any;
  token: string | null;

  constructor() {
    this.api = axios.create({
      baseURL: API_BASE_URL,
      // You can add additional Axios configuration options here, e.g. headers, timeout, etc.
    });
    this.token = localStorage.getItem('token');
  }

  async signup(data: any): Promise<any> {
    try {
      const response = await this.api.post('auth/sign-up', data);
      return response;
    } catch (error) {
      return error;
    }
  }

  async login(data: any) {
    try {
      const response = await this.api.post('student/request-otp', {
        email: data.universityEmail,
        student_code: data.studentId,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async verifyOtp(data: any) {
    try {
      const response = await this.api.post('student/verify-otp', {
        otp: data.otp,
        student_code: data.studentId,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async resendOtp(data: any) {
    try {
      const response = await this.api.post('student/verify', data);
      return response;
    } catch (error) {
      return error;
    }
  }

  async getPreviews() {
    try {
      const response = await this.api.get('student/previews', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async downloadFile(file_key: string) {
    try {
      const response = await this.api.post('student/get-file-url', {
        file_key
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async payment(data: any) {
    try {
      const response = await this.api.post('student/payment', data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async getLoggedInUser() {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        return null;
      }
      const response = await this.api.get('student/me', {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response;
    } catch (error) {
      localStorage.removeItem('token');

      return null;
    }
  }
}

export default new APIService();
