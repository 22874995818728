import { FC } from 'react';
import closeIcon from '../../assets/images/clear.svg';
import copyIcon from '../../assets/images/content_copy.svg';

interface CopyProps {
  handleSetIsModelClose: () => void;
  link: string;
}

const CopyLink: React.FC<CopyProps> = ({ handleSetIsModelClose, link }) => {
  const onClose = async () => {
    handleSetIsModelClose();
  };
  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      handleSetIsModelClose();
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  return (
    <div className='flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50'>
      <div className='w-5/6 lg:w-1/4 m-2'>
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full p-3 bg-white outline-none focus:outline-none'>
          <div className='flex justify-between items-center my-2'>
            <h3 className='text-xl font-semibold'>Copy Link</h3>
            <button onClick={onClose}>
              <img src={closeIcon} alt='close icon' />
            </button>
          </div>
          <div
            className='flex justify-between bg-white border-2 border-ash rounded p-2 mt-2'
            onClick={onCopy}
          >
            <input
              type='text'
              value={link}
              style={{ width: '100%' }}
              disabled
            ></input>
            <img src={copyIcon} alt='copyIcon' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyLink;
