import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import TestImage from './assets/images/logoPlaceholder.png';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import ProtectedArea from './components/ProtectedArea';
import APIService from './network/APIService';
import Inprogress from './pages/Inprogress';
import Login from './pages/Login';
import PaymentPage from './pages/PaymentPage';
import VideoLibrary from './pages/VideoLibrary';

// Replace with fetched data:
interface MyObject {
  title: string;
  image: string;
}

const arrayOfObjects: MyObject[] = [
  {
    title: 'Horizontal(16:9)',
    image: TestImage,
  },
  {
    title: 'Square(1:1)',
    image: TestImage,
  },
  {
    title: 'Vertical(4:5)',
    image: TestImage,
  },
];

function App() {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [paid, setPaid] = useState<boolean>(false);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<boolean[]>(
    Array(arrayOfObjects.length).fill(false)
  );

  const handleLogin = (): void => {
    setLoggedIn(true);
  };

  const handleCheckboxChange = (index: number): void => {
    setCheckedCheckboxes((prevState) => {
      const newCheckedCheckboxes = [...prevState];
      newCheckedCheckboxes[index] = !newCheckedCheckboxes[index];
      return newCheckedCheckboxes;
    });
  };

  useEffect(() => {
    const fetchUser = async () => {
      const user = await APIService.getLoggedInUser();
      setLoggedIn(user !== null);
      setLoading(false);
    };
    fetchUser().catch(console.error);
  });

  return (
    <>
      <div className='h-screen'>
        {isLoggedIn && <Navbar />}
        {isLoading ? (
          ''
        ) : (
          <Routes>
            <Route
              path='/'
              element={
                !isLoggedIn ? (
                  <Login onLogin={handleLogin} />
                ) : (
                  <Navigate to='/library' />
                )
              }
            />
            <Route
              path='/inprogress'
              element={
                <ProtectedArea>
                  <Inprogress />
                </ProtectedArea>
              }
            />

            <Route
              path='/library'
              element={
                <ProtectedArea>
                  <VideoLibrary />
                </ProtectedArea>
              }
            />
            <Route
              path='/library/payment'
              element={
                <ProtectedArea>
                  <PaymentPage
                    checkedCheckboxes={checkedCheckboxes}
                    setCheckedCheckboxes={setCheckedCheckboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    setPaid={setPaid}
                  />
                </ProtectedArea>
              }
            />
          </Routes>
        )}
        {isLoggedIn && <Footer />}
      </div>
      {/* <PaymentPage /> */}
    </>
  );
}

export default App;
