import { FC } from "react";
import logo_updated from "../../assets/images/logo_updated.svg";

const Navbar: FC = () => {
  return (
    <div className="py-8 px-16 bg-white flex justify-center items-center lg:justify-start">
      <a
        href="/"
        className="h-full object-contain"
        onClick={(e) => {
          e.preventDefault(); 
          localStorage.clear(); 
          window.location.href = "/";
        }}
      >
        <img
          src={logo_updated}
          alt="Gradcut Logo"
          className="h-full object-contain"
        />
      </a>
    </div>
  );
};

export default Navbar;
